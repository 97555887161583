/** @format */

import { FormEventHandler, ReactNode } from 'react';

import { FormWrapper, SubmitButton, SubmitButtonWrapper } from './styled';

interface FormProps {
  children: ReactNode;
  submitBtnContent: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export const FormLayout = ({ children, submitBtnContent, onSubmit }: FormProps) => {
  return (
    <FormWrapper onSubmit={onSubmit}>
      {children}
      <SubmitButtonWrapper>
        <SubmitButton type="submit">{submitBtnContent}</SubmitButton>
      </SubmitButtonWrapper>
    </FormWrapper>
  );
};
