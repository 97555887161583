/** @format */

export const firebaseAppConfig = {
  API_KEY_CONFIG: 'AIzaSyC3fO7yzlXgufVruEmKjbHFftLbVcp9doM',
  AUTH_DOMAIN_CONFIG: 'hellofresh-prod-332910.firebaseapp.com',
  PROJECT_ID_CONFIG: 'hellofresh-prod-332910',
  STORAGE_BUCKET_CONFIG: 'hellofresh-prod-332910.appspot.com',
  MESSAGING_SENDERS_ID_CONFIG: '233206055776',
  APP_ID_CONFIG: '',
  MEASUREMENT_ID_CONFIG: ''
};
