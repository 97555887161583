/** @format */

import styled from 'styled-components';
import { Button, Header } from 'common/components';

export const PopUpContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  border-radius: 12px;
  width: 35%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.white};

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const CloseHanderIcon = styled.span`
  position: absolute;
  top: 8%;
  right: 8%;
  font-size: 2em;
  font-weight: 600;
  transition: all 200ms;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const PopUpMessage = styled(Header)`
  color: ${(props) => props.theme.colors.darkenTurquoise};
  text-align: center;
  width: 80%;
  margin: 0 0 2em 0;

  @media (max-width: 768px) {
    margin: 0 0 0.5em 0;
  }
`;

export const PopUpBtnsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const PopUpButton = styled(Button)`
  width: 30%;
  font-size: clamp(0.7em, 1em, 1.05em);
  padding: 12px 6px;
`;

export const SubmitWithoutComment = styled(PopUpButton)`
  background-color: ${(props) => props.theme.colors.inActiveGrey};

  &:hover {
    background-color: ${(props) => props.theme.colors.inActiveGrey};
  }
`;
