/** @format */

export enum RoutesDefinition {
  rating = '/',
  review = '/review',
  congratulation = '/congratulation',

  helloFreshUrl = 'https://www.hellofresh.com/',
  mobileAppDeepLink = 'hellofresh://'
}
