/** @format */

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

html, body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    background-color: ${(props) => props.theme.colors.nicerWhite};
    font-family: 'Source Sans Pro', sans-serif;
}

* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}
`;
