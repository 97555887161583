/** @format */

import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 30%;

  @media (max-width: 768px) {
    width: 80%;
  }
`;
