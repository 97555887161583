/** @format */

import { createContext, ReactNode, useContext, useState } from 'react';

interface SurveyState {
  ratingScore?: number;
  review?: string;
  prompt?: string;
  sessionId?: string;
}

interface SurveyFuntions {
  updateSurveyState: (updatedSurvey: SurveyState) => void;
}

type SurveyContextShape = SurveyState & SurveyFuntions;

const SurveyContext = createContext<SurveyContextShape | undefined>(undefined);

interface SurveyContextProviderProps {
  children: ReactNode;
}

export const SurveyContextProvider = ({ children }: SurveyContextProviderProps) => {
  const [state, setState] = useState<SurveyState>();

  function updateSurveyState(updatedSurvey: SurveyState) {
    setState({ ...state, ...updatedSurvey });
  }

  const ctxValues: SurveyContextShape = {
    ratingScore: state?.ratingScore ?? -1,
    review: state?.review ?? '',
    prompt: state?.prompt ?? '',
    sessionId: state?.sessionId ?? '',
    updateSurveyState
  };

  return <SurveyContext.Provider value={ctxValues}>{children}</SurveyContext.Provider>;
};

export const useSurvey = () => {
  const surveyCtx = useContext(SurveyContext);
  if (surveyCtx === undefined) {
    throw new Error('useSurvey must be used within a UserProvider');
  }
  return surveyCtx;
};
