/** @format */
import { useEffect } from 'react';

import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import { StateWrapper } from 'state';
import theme from 'common/theme/theme';
import { GlobalStyle } from 'common/theme';
import { app } from 'common/utils/firebase';
import { RoutesSchema } from 'routing/Routes';
import { ToastNotification } from 'common/components';
import { getPerformance } from 'firebase/performance';

const queryClient = new QueryClient();

function App(): React.ReactElement {
  useEffect(() => {
    getPerformance(app);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StateWrapper>
        <QueryClientProvider client={queryClient}>
          <GlobalStyle />
          <RoutesSchema />
          <ToastNotification />
        </QueryClientProvider>
      </StateWrapper>
    </ThemeProvider>
  );
}

export default App;
