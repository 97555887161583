/** @format */
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { logEvent } from 'firebase/analytics';

import { usePopup, useSurvey } from 'state';
import { analytics } from 'common/utils/firebase';
import { RoutesDefinition } from 'common/constant';
import { submitReview } from 'features/ReviewSurvey/api';
import {
  PopUpButton,
  PopUpContainer,
  PopUpMessage,
  CloseHanderIcon,
  PopUpBtnsWrapper,
  SubmitWithoutComment
} from './styled';

interface WithoutReviewPopupProps {
  message: string;
  backReviewMessageBtn: string;
  withoutReviewBtn: string;
}

export const WithoutReviewPopup = ({ message, backReviewMessageBtn, withoutReviewBtn }: WithoutReviewPopupProps) => {
  const { ratingScore, sessionId, updateSurveyState } = useSurvey();
  const { updatePopupState, modalType } = usePopup();
  const history = useNavigate();

  const fullCurrentUrl = window.location.href;

  const { mutate: submitMutateReview, isSuccess } = useMutation({
    mutationFn: submitReview,
    onSuccess: ({ data }) => {
      updateSurveyState({ sessionId: data.session_id, prompt: data.message });
    }
  });

  const closePopUpHandle = () => {
    updatePopupState({ isOpen: false, modalType });
  };

  useEffect(() => {
    if (isSuccess) {
      closePopUpHandle();
      history(RoutesDefinition.congratulation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isSuccess]);

  const submitWithoutComment = () => {
    submitMutateReview({
      score: ratingScore,
      review: '',
      url: fullCurrentUrl,
      sessionId,
      empty_review: true
    });
  };

  return (
    <PopUpContainer>
      <PopUpMessage>{message}</PopUpMessage>
      <CloseHanderIcon
        onClick={() => {
          closePopUpHandle();
          logEvent(analytics, 'without_review_popup_close_icon');
        }}
      >
        &times;
      </CloseHanderIcon>
      <PopUpBtnsWrapper>
        <PopUpButton
          onClick={() => {
            closePopUpHandle();
            logEvent(analytics, 'without_review_popup_back_comment');
          }}
        >
          {backReviewMessageBtn}
        </PopUpButton>
        <SubmitWithoutComment
          onClick={() => {
            submitWithoutComment();
            logEvent(analytics, 'without_review_popup_submit_anyway');
          }}
        >
          {withoutReviewBtn}
        </SubmitWithoutComment>
      </PopUpBtnsWrapper>
    </PopUpContainer>
  );
};
