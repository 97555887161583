/** @format */

import { ReactNode } from 'react';

import { PopupContextProvider, SurveyContextProvider, ToastContextProvider } from 'state';

interface StateWrapperProps {
  children: ReactNode;
}

export const StateWrapper = ({ children }: StateWrapperProps) => (
  <SurveyContextProvider>
    <PopupContextProvider>
      <ToastContextProvider>{children}</ToastContextProvider>
    </PopupContextProvider>
  </SurveyContextProvider>
);
