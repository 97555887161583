/** @format */

import styled from 'styled-components';

interface SnackbarProps {
  isOpen: boolean;
}

export const Snackbar = styled.div<SnackbarProps>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  width: 15%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 1em;
  border-radius: 12px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  background-color: ${(props) => props.theme.colors.errorRed};
  z-index: 1000;

  @media (max-width: 768px) {
    bottom: 5%;
    width: 50%;
  }
`;

export const Alert = styled.p`
  color: ${(props) => props.theme.colors.whiteFontColor};
  font-size: clamp(0.7em, 1.1em, 1.2em);
`;
