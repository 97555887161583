/** @format */

import HelloFreshLogo from 'common/images/HelloFreshLogo.svg';

export const layoutConfig = {
  logoUrl: HelloFreshLogo,
  logoAlt: 'HelloFresh logo'
};

export const ratingConfig = {
  header: 'How likely is it that you would recommend HelloFresh to a friend or colleague?',
  ratingLabels: [{ ratingLabelName: '0 - Not at all likely' }, { ratingLabelName: '10 - Extremely likely' }]
};

export const reviewConfig = {
  ratingReactionValues: {
    from0to6: `We're very sorry you're not 100% happy with HelloFresh! Now it's time for us to get to work on improving it for next time. What went wrong?`,
    from7to8: `Okay isn't quite good enough for us. We want to do better! Please tell us how we could earn a higher score.`,
    from9to10: `Awesome! So happy to hear you're pleased with HelloFresh. Help us keep it that way by explaining the reasons for your score.`
  },
  popUp: {
    message: 'Have a greater impact on your future experience by providing us with more detailed information.',
    backReviewMessageBtn: 'Back to comment',
    withoutReviewBtn: 'Submit anyway'
  }
};

export const congratulationConfig = {
  header: 'All done! Thanks!',
  btnContent: 'Return to App'
};

export const reviewAdjustmentPopup = {
  promptInstruction: `Help us improve by providing more details in your comment!`
};
