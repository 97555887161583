/** @format */

import { Dispatch, SetStateAction } from 'react';

import { RatingItem } from 'features';
import { RatingLabelsType } from 'common/types';
import { RatingButtons, RatingLabels, RatingContent } from './styled';

interface RatingComponentProps {
  ratingLabels: RatingLabelsType[];
  hover: number;
  setHover: Dispatch<SetStateAction<number>>;
}

export const RatingComponent = ({ ratingLabels, hover, setHover }: RatingComponentProps) => {
  return (
    <>
      <RatingButtons>
        {[...Array(11)].map((_star, i) => (
          <RatingItem ratingButtonValue={i} hover={hover} changeHoverFun={setHover} key={i} />
        ))}
      </RatingButtons>
      <RatingLabels>
        {ratingLabels.map(({ ratingLabelName }, index) => (
          <RatingContent key={index}>{ratingLabelName}</RatingContent>
        ))}
      </RatingLabels>
    </>
  );
};
