/** @format */
import { logEvent } from 'firebase/analytics';

import { ButtonWrapper } from './styled';
import { Header, Button } from 'common/components';
import { RoutesDefinition } from 'common/constant';
import { analytics } from 'common/utils/firebase';

interface CongratulationProps {
  header: string;
  btnContent: string;
}

export const Congratulation = ({ header, btnContent }: CongratulationProps) => {
  const handleClick = () => {
    logEvent(analytics, 'thanks_back_to_app');
  };

  const mobileDetectionCond = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  return (
    <>
      <Header>{header}</Header>
      <ButtonWrapper>
        <a href={mobileDetectionCond ? RoutesDefinition.mobileAppDeepLink : RoutesDefinition.helloFreshUrl}>
          <Button onClick={() => handleClick()}>{btnContent}</Button>
        </a>
      </ButtonWrapper>
    </>
  );
};
