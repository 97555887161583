/** @format */
import { useEffect } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useSurvey, usePopup } from 'state';
import { ParagraphWrapper } from './styled';
import { logEvent } from 'firebase/analytics';
import { ClientOpinionForm } from 'common/types';
import { analytics } from 'common/utils/firebase';
import { RoutesDefinition } from 'common/constant';
import { submitReview } from 'features/ReviewSurvey/api';
import { concateStringWithSeperator } from 'common/utils';
import { FormLayoutSubmission, Paragraph } from 'common/components';
interface TextFieldSurveyProps {
  reviews: {
    from0to6: string;
    from7to8: string;
    from9to10: string;
  };
}

export const ReviewSurvey = ({ reviews: { from0to6, from7to8, from9to10 } }: TextFieldSurveyProps) => {
  const { ratingScore, review, sessionId, prompt, updateSurveyState } = useSurvey();
  const { updatePopupState, modalType } = usePopup();
  const { reset } = useForm();
  const history = useNavigate();
  const fullCurrentUrl = window.location.href;

  const changeModalState = (isResponseInformative: boolean) => {
    if (!isResponseInformative) {
      updatePopupState({ isOpen: true, modalType: 'review_attempt_popup' });
      return;
    }
    updatePopupState({ isOpen: false, modalType });
    history(RoutesDefinition.congratulation);
  };

  const { mutate: submitMutateReview } = useMutation({
    mutationFn: submitReview,
    onSuccess: ({ data }) => {
      updateSurveyState({ prompt: data.message, sessionId: data.session_id });

      changeModalState(data.informative);

      if (prompt === data.message) {
        updatePopupState({ isOpen: false, modalType });
        logEvent(analytics, 'review_view_twice_same_respone');
        history(RoutesDefinition.congratulation);
      }

      logEvent(analytics, 'review_view_informative', { informative: data.informative });
    }
  });
  const handleSubmit: SubmitHandler<ClientOpinionForm> = (requestedData) => {
    const { clientOpinion } = requestedData;
    const reviewLength = clientOpinion.length;

    if (reviewLength === 0) {
      updatePopupState({ isOpen: true, modalType: 'attempt_without_review_popup' });
      logEvent(analytics, 'review_view_open_without_review_popup');
    } else {
      updateSurveyState({
        review: review === undefined ? clientOpinion : concateStringWithSeperator(review, clientOpinion, ';')
      });

      submitMutateReview({
        score: ratingScore,
        review: review === undefined ? clientOpinion : concateStringWithSeperator(review, clientOpinion, '.'),
        url: fullCurrentUrl,
        sessionId,
        empty_review: false
      });
      reset({ clientOpinion: '' });
      logEvent(analytics, 'review_view_review_attempt', { reviewLength });
    }
  };

  useEffect(() => {
    if (ratingScore === -1) history(RoutesDefinition.rating);
  }, [history, ratingScore]);

  const reviewCondition = (score: number) => {
    if (score >= 0 && score < 7) {
      return from0to6;
    } else if (score >= 7 && score < 9) {
      return from7to8;
    } else if (score >= 9 && score < 11) {
      return from9to10;
    }
  };

  return (
    <>
      <ParagraphWrapper>
        <Paragraph>{ratingScore !== undefined && reviewCondition(ratingScore)}</Paragraph>
      </ParagraphWrapper>
      <FormLayoutSubmission onSubmit={handleSubmit} />
    </>
  );
};
