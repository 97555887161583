/** @format */
import { useEffect } from 'react';

import { useToast } from 'state';
import { Alert, Snackbar } from './styled';

export const ToastNotification = () => {
  const { message, isOpen, updateToastState } = useToast();

  useEffect(() => {
    const hideToast = () => {
      setTimeout(() => {
        updateToastState({ isOpen: false });
      }, 5000);
    };

    hideToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Snackbar isOpen={isOpen}>
          <Alert>{message}</Alert>
        </Snackbar>
      )}
    </>
  );
};
