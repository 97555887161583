/** @format */

import styled from 'styled-components';
import { Button } from '../Button';

export const FormWrapper = styled.form`
  margin: 0 1em;
  width: 100%;
  height: 100%;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0;
`;

export const SubmitButton = styled(Button)`
  width: 20%;

  @media (max-width: 768px) {
    width: 80%;
    margin: 0;
  }
`;
