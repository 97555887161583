/** @format */
import { ReactNode } from 'react';

import { usePopup } from 'state';
import { PopUpModal } from 'common/components';
import { SurveyPageWrapper, SurveyPageContentContainer, LogoContainer, Logo, ContentWrapper } from './styled';

interface SurveyTemplateProps {
  logoUrl: string;
  logoAlt: string;
  children: ReactNode;
}

export const SurveyPageLayout = ({ children, logoAlt, logoUrl }: SurveyTemplateProps) => {
  const { isOpen, modalType } = usePopup();

  return (
    <SurveyPageWrapper>
      <PopUpModal isOpen={isOpen} modalType={modalType} />
      <SurveyPageContentContainer>
        <ContentWrapper>
          <LogoContainer>
            <Logo src={logoUrl} alt={logoAlt} />
          </LogoContainer>
          {children}
        </ContentWrapper>
      </SurveyPageContentContainer>
    </SurveyPageWrapper>
  );
};
