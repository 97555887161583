/** @format */

import { Paragraph } from 'common/components';
import styled from 'styled-components';

export const ParagraphWrapper = styled.div`
  width: 100%;
  margin: 1em 0;
`;
export const ResponseMessage = styled(Paragraph)`
  color: ${(props) => props.theme.colors.yellowWarning};
  font-weight: 700;
`;
