/** @format */

import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  padding: 12px 24px;
  background-color: ${(props) => props.theme.colors.darkenTurquoise};
  color: ${(props) => props.theme.colors.whiteFontColor};
  font-size: clamp(1em, 1.2em, 1.4em);
  border-radius: 7px;
  transition: transform 250ms cubic-bezier(0.2, 0.8, 0.4, 1);
  text-align: center;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
    color: ${(props) => props.theme.colors.whiteFontColor};
    background-color: ${(props) => props.theme.colors.lightenTurquoise};
  }

  &:active {
    transform: scale(0.9);
  }
`;
