/** @format */

import { OverlayWrapper } from './styled';
import { ModalTypes } from 'common/types';
import { reviewConfig, reviewAdjustmentPopup } from 'config';
import { ReviewAdjustmentPopup, WithoutReviewPopup } from 'common/components';

interface PopUpModalProps {
  isOpen: boolean;
  modalType?: ModalTypes;
}

export const PopUpModal = ({ isOpen, modalType }: PopUpModalProps) => {
  const popupResolver = () => {
    switch (modalType) {
      case 'attempt_without_review_popup': {
        return {
          component: (
            <WithoutReviewPopup
              message={reviewConfig.popUp.message}
              backReviewMessageBtn={reviewConfig.popUp.backReviewMessageBtn}
              withoutReviewBtn={reviewConfig.popUp.withoutReviewBtn}
            />
          )
        };
      }
      case 'review_attempt_popup': {
        return {
          component: <ReviewAdjustmentPopup promptInstruction={reviewAdjustmentPopup.promptInstruction} />
        };
      }
      default: {
        return { component: null };
      }
    }
  };

  const { component } = popupResolver();

  return <>{isOpen && <OverlayWrapper>{component}</OverlayWrapper>}</>;
};
