/** @format */
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';

import { RatingSurvey } from 'features';
import { RatingForm } from 'common/types';
import { useSurvey, useToast } from 'state';
import { SurveyPageLayout } from 'templates';
import { analytics } from 'common/utils/firebase';
import { RoutesDefinition } from 'common/constant';
import { ratingConfig, layoutConfig } from 'config';

const RatingPage = () => {
  const { ratingScore } = useSurvey();
  const { updateToastState } = useToast();
  const history = useNavigate();

  const handleSubmit: SubmitHandler<RatingForm> = () => {
    if (ratingScore === -1) {
      logEvent(analytics, 'ratingView_no_rate');
      updateToastState({ isOpen: true, message: 'Choose your rating, please.' });
    } else {
      updateToastState({ isOpen: false });
      logEvent(analytics, 'ratingView_rate_submitted', { ratingScore });
      history(RoutesDefinition.review);
    }
  };

  return (
    <SurveyPageLayout logoUrl={layoutConfig.logoUrl} logoAlt={layoutConfig.logoAlt}>
      <RatingSurvey header={ratingConfig.header} ratingLabels={ratingConfig.ratingLabels} onSubmit={handleSubmit} />
    </SurveyPageLayout>
  );
};

export default RatingPage;
