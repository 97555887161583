/** @format */

import styled from 'styled-components';
import { TextField, Paragraph, Button } from 'common/components';

export const ReviewAttemptContainer = styled.div`
  width: 35%;
  height: 30%;
  padding: 2em;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    margin-bottom: 0.5em;
  }

  @media (max-width: 768px) {
    height: 50%;
    width: 80%;
  }
`;

export const PreviousReviewContainer = styled.div`
  background-color: ${(props) => props.theme.colors.lightGrey};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  min-height: 50px;
  text-align: center;
  width: 70%;
  border-radius: 12px;
  margin: 1em 0;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const PromptWrapper = styled.div``;

export const PreviousReview = styled(TextField)`
  background-color: ${(props) => props.theme.colors.lightGrey};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  height: inherit;
  text-align: center;
  &:hover {
    border: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AdjustedReview = styled(TextField)`
  border: 1px solid grey;
`;

export const ReviewButton = styled(Button)`
  display: flex;
  justify-content: space-evenly;
  width: 30%;
  font-size: clamp(0.7em, 0.95em, 1.05em);
  padding: 12px 4px;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightenTurquoise};
    color: ${(props) => props.theme.colors.whiteFontColor};
  }

  @media (max-width: 768px) {
    width: 60%;
  }
`;

export const ReviewButtonIcon = styled.img``;

export const BoldParagraph = styled(Paragraph)`
  font-weight: 600;
`;
