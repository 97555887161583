/** @format */

export const colors = {
  white: '#ffffff',
  nicerWhite: '#f1f1f1',
  turquoise: '#5ca69f',
  darkenTurquoise: '#035e5f',
  lightenTurquoise: '#048485',
  whiteFontColor: '#fefefe',
  errorRed: '#ED705F',
  yellowWarning: '#eddc6b',
  lightGrey: '#EFF2F5',
  black: '#000000',
  inActiveGrey: '#A8A8A8'
};

const theme = {
  colors
};

export type Theme = typeof theme;

export default theme;
