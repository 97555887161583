/** @format */

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ClientOpinionForm } from 'common/types';
import { FormLayout, TextField } from 'common/components';

interface FormLayoutSubmissionProps {
  onSubmit: SubmitHandler<ClientOpinionForm>;
}

export const FormLayoutSubmission = ({ onSubmit }: FormLayoutSubmissionProps) => {
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      clientOpinion: ''
    }
  });

  return (
    <FormProvider {...formMethods}>
      <FormLayout onSubmit={formMethods.handleSubmit(onSubmit)} submitBtnContent="Next">
        <TextField {...formMethods.register('clientOpinion')} autoFocus />
      </FormLayout>
    </FormProvider>
  );
};
