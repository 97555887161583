/** @format */

import axios from 'axios';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'X-API-Key': `${process.env.REACT_APP_X_API_KEY}`
  }
});
