/** @format */

import styled from 'styled-components';

export const OverlayWrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;
