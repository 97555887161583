/** @format */

import { apiClient } from 'common/utils/api';

interface SubmitReviewSchema {
  url: string;
  review: string;
  score?: number;
  sessionId?: string;
  frontend_page?: string;
  empty_review?: boolean;
}

export const submitReview = ({ score, review, url, sessionId, empty_review }: SubmitReviewSchema) => {
  return apiClient.get(
    `/process-review?score=${score}&review=${review}&url=${url}&session_id=${sessionId}&empty_review=${empty_review}`
  );
};
