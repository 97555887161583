/** @format */

import { Route, Routes } from 'react-router-dom';
import RatingPage from 'pages/RatingPage';
import ReviewsPage from 'pages/ReviewsPage';
import CongratulationPage from 'pages/Congratulation';

export const RoutesSchema = (): JSX.Element => (
  <Routes>
    <Route path="/" element={<RatingPage />} />
    <Route path="/review" element={<ReviewsPage />} />
    <Route path="/congratulation" element={<CongratulationPage />} />
  </Routes>
);
