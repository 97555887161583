/** @format */

import styled from 'styled-components';

export const TextField = styled.textarea`
  resize: none;
  width: 70%;
  height: 40%;
  border: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 7px;
  padding: 1em;
  font-size: clamp(0.9em, 1.1em, 1.2em);
  align-items: center;

  @media (max-width: 768px) {
    padding: 0.5em;
  }
`;
