/** @format */

import styled from 'styled-components';

export const SurveyPageWrapper = styled.main`
  width: 100vw;
  height: 100vh;
  position: relative;

  @media (max-width: 768px) {
    background-color: ${(props) => props.theme.colors.turquoise};
  }
`;

export const SurveyPageContentContainer = styled.div`
  max-width: 1050px;
  width: 100%;
  height: 60%;
  background-color: ${(props) => props.theme.colors.turquoise};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.whiteFontColor};

  @media (max-width: 768px) {
    height: 80%;
    border-radius: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 3em;

  @media (max-width: 768px) {
    margin: 0 1em 2em 1em;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  width: 20%;

  @media (max-width: 768px) {
    width: 50%;
  }
`;
export const Logo = styled.img`
  width: 100%;
  height: auto;
`;
