/** @format */
import { Dispatch, SetStateAction } from 'react';

import { useFormContext } from 'react-hook-form';

import { useSurvey } from 'state';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'common/utils/firebase';
import { RatingItemContainer, RatingLabel, StarWrapper } from './styled';

interface RatingItemProps {
  hover: number;
  ratingButtonValue: number;
  changeHoverFun: Dispatch<SetStateAction<number>>;
}

export const RatingItem = ({ ratingButtonValue, hover, changeHoverFun }: RatingItemProps) => {
  const { setValue } = useFormContext();
  const { updateSurveyState, ratingScore } = useSurvey();

  const handleRatingButtonClick = () => {
    updateSurveyState({ ratingScore: ratingButtonValue });
    logEvent(analytics, 'ratingView_click_rate_without_submit', { ratingButtonValue });
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <RatingItemContainer
      type="button"
      name="ratingScore"
      value={ratingButtonValue}
      onClick={handleRatingButtonClick}
      isActive={ratingScore !== undefined && (ratingButtonValue <= hover || ratingButtonValue <= ratingScore)}
      onMouseEnter={() => changeHoverFun(ratingButtonValue)}
      onMouseLeave={() => changeHoverFun(-1)}
    >
      <input {...setValue('ratingScore', ratingScore)} type="hidden" />
      <StarWrapper />
      <RatingLabel>{ratingButtonValue}</RatingLabel>
    </RatingItemContainer>
  );
};
