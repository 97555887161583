/** @format */

// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { firebaseAppConfig } from 'common/constant';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: firebaseAppConfig.API_KEY_CONFIG,
  authDomain: firebaseAppConfig.AUTH_DOMAIN_CONFIG,
  projectId: firebaseAppConfig.PROJECT_ID_CONFIG,
  storageBucket: firebaseAppConfig.STORAGE_BUCKET_CONFIG,
  messagingSenderId: firebaseAppConfig.MESSAGING_SENDERS_ID_CONFIG,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
