/** @format */

import { Congratulation } from 'features';
import { SurveyPageLayout } from 'templates';
import { congratulationConfig, layoutConfig } from 'config';

const CongratulationPage = () => {
  return (
    <SurveyPageLayout logoUrl={layoutConfig.logoUrl} logoAlt={layoutConfig.logoAlt}>
      <Congratulation header={congratulationConfig.header} btnContent={congratulationConfig.btnContent} />
    </SurveyPageLayout>
  );
};

export default CongratulationPage;
