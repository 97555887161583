/** @format */

import { Button } from 'common/components';
import styled from 'styled-components';

export const RatingComponentWrapper = styled.form`
  margin: 0 3em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const RatingButtons = styled.div`
  width: 100%;
  display: flex;
  padding: 1em 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const RatingLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2em;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0 0.5em;
  }
`;
export const RatingContent = styled.span`
  font-size: clamp(0.7em, 1.2em, 1.35em);
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubmitButton = styled(Button)`
  margin: 1em 0;
  width: 20%;

  @media (max-width: 768px) {
    width: 80%;
  }
`;
