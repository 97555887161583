/** @format */

import styled from 'styled-components';
import { IoRadioButtonOnOutline } from 'react-icons/io5';

interface IsActivable {
  isActive: boolean;
}

export const RatingItemContainer = styled.button<IsActivable>`
  height: 100px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: ${(props) => (props.isActive ? props.theme.colors.whiteFontColor : props.theme.colors.darkenTurquoise)};
  opacity: 0.7;

  @media (max-width: 768px) {
    width: 14%;
    padding: 8px 0;
  }
`;

export const StarWrapper = styled(IoRadioButtonOnOutline)`
  height: auto;
  width: 100%;
`;

export const RatingLabel = styled.span`
  font-size: 2em;
`;
