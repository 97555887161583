/** @format */

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useSurvey, usePopup } from 'state';
import { logEvent } from 'firebase/analytics';
import { ClientOpinionForm } from 'common/types';
import { analytics } from 'common/utils/firebase';
import { RoutesDefinition } from 'common/constant';
import { submitReview } from 'features/ReviewSurvey/api';
import { concateStringWithSeperator } from 'common/utils';
import { FormLayoutSubmission, Paragraph } from 'common/components';
import { ReviewAttemptContainer, BoldParagraph, PreviousReviewContainer, PromptWrapper } from './styled';

interface ReviewAdjustmentPopupProps {
  promptInstruction: string;
}

export const ReviewAdjustmentPopup = ({ promptInstruction }: ReviewAdjustmentPopupProps) => {
  const { ratingScore, review, sessionId, prompt, updateSurveyState } = useSurvey();
  const { updatePopupState, modalType } = usePopup();
  const { reset } = useForm();
  const history = useNavigate();
  const fullCurrentUrl = window.location.href;

  const hidePopupAndMoveToCongratulationPage = () => {
    updatePopupState({ isOpen: false, modalType });
    history(RoutesDefinition.congratulation);
  };

  const { mutate: submitMutateReview } = useMutation({
    mutationFn: submitReview,
    onSuccess: ({ data }) => {
      updateSurveyState({ prompt: data.message, sessionId: data.session_id });
      logEvent(analytics, 'adjustment_popup_submission', { informative: data.informative });
    }
  });

  const handleSubmit: SubmitHandler<ClientOpinionForm> = (requestedData) => {
    const { clientOpinion } = requestedData;
    const reviewLength = clientOpinion.length;

    // Handle empty review
    if (reviewLength === 0) {
      updatePopupState({ isOpen: false, modalType });
      logEvent(analytics, 'adjustment_popup_empty_review');
      history(RoutesDefinition.congratulation);
      return;
    }

    updateSurveyState({
      review: review === undefined ? clientOpinion : concateStringWithSeperator(review, clientOpinion, ';')
    });

    submitMutateReview({
      score: ratingScore,
      review: review === undefined ? clientOpinion : concateStringWithSeperator(review, clientOpinion, '.'),
      url: fullCurrentUrl,
      sessionId,
      empty_review: false
    });
    reset({ clientOpinion: '' });
    hidePopupAndMoveToCongratulationPage();
    logEvent(analytics, 'adjustment_popup_review_attempt', { reviewLength });
  };

  return (
    <ReviewAttemptContainer>
      <PreviousReviewContainer>
        <Paragraph>{review}</Paragraph>
      </PreviousReviewContainer>
      <BoldParagraph>{promptInstruction}</BoldParagraph>
      <PromptWrapper>
        <Paragraph>{prompt}</Paragraph>
      </PromptWrapper>
      <FormLayoutSubmission onSubmit={handleSubmit} />
    </ReviewAttemptContainer>
  );
};
