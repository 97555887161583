/** @format */

import { useState } from 'react';

import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';

import { RatingComponent } from 'features';
import { FormLayout, Header } from 'common/components';
import { RatingForm, RatingLabelsType } from 'common/types';

interface RatingSurveyProps {
  header: string;
  ratingLabels: RatingLabelsType[];
  onSubmit: SubmitHandler<RatingForm>;
}

export const RatingSurvey = ({ header, ratingLabels, onSubmit }: RatingSurveyProps) => {
  const [hover, setHover] = useState<number>(-1);

  const formMethods = useForm<RatingForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ratingScore: -1
    }
  });

  return (
    <FormProvider {...formMethods}>
      <Header>{header}</Header>
      <FormLayout onSubmit={formMethods.handleSubmit(onSubmit)} submitBtnContent="Next">
        <RatingComponent hover={hover} setHover={setHover} ratingLabels={ratingLabels} />
      </FormLayout>
    </FormProvider>
  );
};
