/** @format */

import { createContext, ReactNode, useContext, useState } from 'react';

interface ToastState {
  isOpen: boolean;
  message?: string;
}

interface ToastFuntions {
  updateToastState: (updatedToast: ToastState) => void;
}

type ToastContextShape = ToastState & ToastFuntions;

const ToastContext = createContext<ToastContextShape | undefined>(undefined);

interface ToastContextProviderProps {
  children: ReactNode;
}

export const ToastContextProvider = ({ children }: ToastContextProviderProps) => {
  const [state, setState] = useState<ToastState>();

  function updateToastState(updatedToast: ToastState) {
    setState({ ...state, ...updatedToast });
  }

  const ctxValues: ToastContextShape = {
    isOpen: state?.isOpen ?? false,
    message: state?.message ?? '',
    updateToastState
  };

  return <ToastContext.Provider value={ctxValues}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
  const toastCtx = useContext(ToastContext);
  if (toastCtx === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return toastCtx;
};
