/** @format */
import { createContext, ReactNode, useContext, useState } from 'react';

import { ModalTypes } from 'common/types';

interface PopupState {
  isOpen: boolean;
  modalType?: ModalTypes;
}

interface PopupFunctions {
  updatePopupState: (updatedPopupState: PopupState) => void;
}

type PopupContextShape = PopupState & PopupFunctions;

const PopupContext = createContext<PopupContextShape | undefined>(undefined);

interface PopupContextProviderProps {
  children: ReactNode;
}

export const PopupContextProvider = ({ children }: PopupContextProviderProps) => {
  const [popUpState, setPopupState] = useState<PopupState>();

  function updatePopupState(updatedPopupState: PopupState) {
    setPopupState(updatedPopupState);
  }

  const ctxValues: PopupContextShape = {
    isOpen: popUpState?.isOpen ?? false,
    modalType: popUpState?.modalType ?? undefined,
    updatePopupState
  };

  return <PopupContext.Provider value={ctxValues}>{children}</PopupContext.Provider>;
};

export const usePopup = () => {
  const popupCtx = useContext(PopupContext);
  if (popupCtx === undefined) {
    throw new Error('usePopup must be within a PopupProvider');
  }
  return popupCtx;
};
