/** @format */

import { ReviewSurvey } from 'features';
import { SurveyPageLayout } from 'templates';
import { layoutConfig, reviewConfig } from 'config';

const ReviewsPage = () => {
  return (
    <SurveyPageLayout logoUrl={layoutConfig.logoUrl} logoAlt={layoutConfig.logoAlt}>
      <ReviewSurvey
        reviews={{
          from0to6: reviewConfig.ratingReactionValues.from0to6,
          from7to8: reviewConfig.ratingReactionValues.from7to8,
          from9to10: reviewConfig.ratingReactionValues.from9to10
        }}
      />
    </SurveyPageLayout>
  );
};

export default ReviewsPage;
